<section class="lg:flex md:sm:grid md:sm:grid-cols-1">
  <article class="lg:flex box pt-2 pb-2 pl-4 pr-4 rounded w-64 mr-4 mb-2">
    <app-input-search [placeholder]="placeholder" (submitEventEmitter)="search($event)"></app-input-search>
  </article>
  <article class="lg:flex lg:mr-4 rounded mb-2" (click)="filterOpened = !filterOpened">
    <button id="buttonOpenFilter" 
      class="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
      [ngClass]="{ 'opened' : filterOpened }">
      <img [src]="images.filter" alt="filter" class="w-6 h-6 mr-2" [ngClass]="{ 'filterOpened': filterOpened }" />
      <span class="font-semibold text-base">{{ literalService.get("users.filter", true) }}</span>
    </button>
  </article>
  <article class="flex absolute mt-20 w-auto z-10 bg-white" [ngClass]="{ 'hidden': !filterOpened }">
    <app-filter (filter)="filter.emit($event)" (closeFilter)="filterOpened = false" [componentOpen]="'buttonOpenFilter'"></app-filter>
  </article>
  <article id="sort" class="lg:flex lg:mr-4 rounded mb-2" (click)="sortedOpened = !sortedOpened">
    <button id="buttonOpenSort" 
      class="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
    >
      <img [src]="images.sort" alt="sort" class="w-6 h-6 mr-2" />
      <span class="font-semibold text-base">{{
        literalService.get("users.sort", true)
      }}</span>
    </button>
  </article>
  <article id="sortModal" class="flex absolute mt-20 w-auto z-10 bg-white" [ngClass]="{ 'hidden': !sortedOpened }">
    <app-sort (closeSort)="sortedOpened = false" (sort)="sort.emit()" [componentOpen]="'buttonOpenSort'"></app-sort>
  </article>
  @if (searching) {
    <article class="flex items-center pb-2">
      <img [src]="images.loading" alt="loading" class="h-10 w-10 ml-4" />
      <label class="font-semibold text-base ml-4">{{ literalService.get('users.searchingUsers', true) }}...</label>
    </article>
  }
  <!-- <article class="lg:flex lg:mr-4 rounded mb-2">
    <button class="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
      <img [src]="images.visibilityOff" alt="visibilityOff" class="w-6 h-6 mr-2"/>
      <span class="font-normal">{{ literalService.get("users.manageColumns", true) }}</span>
    </button>
  </article>
  <article class="lg:flex lg:mr-4 rounded mb-2 inline-flex items-center">
    <img [src]="images.download" alt="download" class="w-8 h-8 mr-2" />
  </article> -->
  <section class="flex ml-auto">
    <!-- TODO: Uncomment this when we use me/permissions  -->
    <!-- @if (adminRole !== 'OPERATOR' && roleService.permissions['USER']['CREATE']) { -->
    @if (adminRole !== 'OPERATOR') {
      <article
        class="lg:flex lg:mr-4 rounded mb-2 inline-flex items-center ml-auto border border-black cursor-pointer"
        style="border-radius: 30px; padding: 10px; width: 65px; border-width: 2px"
        (click)="addUserDriverEventEmitter.emit()" 
      >
        <img [src]="images.user.addDriver" alt="addDriver" class="w-8 h-8 ml-1" />
      </article>
    }
    <!-- TODO: Uncomment this when we use me/permissions  -->
    <!-- @if (adminRole !== 'DRIVER_MANAGER' && roleService.permissions['USER']['CREATE']) { -->
    @if (adminRole !== 'DRIVER_MANAGER') {
      <article
        class="lg:flex lg:mr-4 rounded mb-2 inline-flex items-center ml-auto border border-black cursor-pointer"
        style="border-radius: 30px; padding: 10px; width: 65px; border-width: 2px"
        (click)="addUserEventEmitter.emit()" 
      >
        <img [src]="images.user.addUser" alt="addUser" class="w-8 h-8 ml-1" />
      </article>
    }
  </section>
</section>
